import { Footer } from '../components/shell/Footer';
import { Header } from '../components/shell/Header';
import { Navbar } from '../components/shell/Navbar';
import { Outlet } from 'react-router';
import { Suspense } from 'react';

const DefaultLayout = () => (
  <>
    <Suspense>
      <Header />
      <Navbar />
    </Suspense>
    <Outlet />
    <Suspense>
      <Footer />
    </Suspense>
  </>
);

export default DefaultLayout;
